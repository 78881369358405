import Swup from 'swup';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';

const swup = new Swup({
	plugins: [
		new SwupPreloadPlugin(),
		new SwupScrollPlugin({
			doScrollingRightAway: false,
			animateScroll: false
		})]
});

$(document).ready(function () {

	init();

	function init() {

		/* GENERAL */
		detectOperatingSystem()
		watchForHover()
		// deleteLSep()
		searchbarOpen();
		searchbarSwitchLang();
		searchbarCloses();
		searchRequest();
		clickOnLogoCloseAll();
		generalBreakAfterSlash()
		resizeSetHeight()
		scrollOnImgClick();
		scrollToTop();

		/* MENU */
		toggleMenu();
		menuKompetenzMobile()
		maskScrollbars()

		/* HOME */
		toggleArticles();
		awardsCarousel();

		if ($("#home")[0]) {
			fadeOutCornerLogo()
			homeSlideshow()
		}

		/* KANZLEI */
		tableCollapsible();
		mapOnHover();

		/* FILTER */
		filterReset()

		if (document.querySelector('#component__team-filter')) {
			filterOverlay()
			filterReset()
			filterBreakAfterSlash()
			filterFunctionality()
			subfilterFunctionality()
			filterPredefined()
			filterNoResultsCheck()
			mobileFilterStyling()
			mobileFilterFunctionality()
			showNavRight()
		}

	}

	swup.on('contentReplaced', init);
	swup.on('contentReplaced', scrollOnImgClick);
	swup.on('contentReplaced', filterReset);
	swup.on('pageView', init);


	var resizeTimer;

	$(window).resize(function () {

		$(".menu__scrollbarmask.mask--main").hide().css("left", "unset");

		clearTimeout(resizeTimer);

		resizeTimer = setTimeout(function () {

			viewportHeight = $(window).innerHeight()

			resizeMenu()
			resizeSetHeight()
			menuKompetenzMobile()
			// if ($("body").hasClass("hasHover")) {
			// }

			var maskScrollbarTimer;

			clearTimeout(maskScrollbarTimer);

			maskScrollbarTimer = setTimeout(function () {
				maskScrollbars()
			}, 500);

			$(window).on("orientationchange", function (event) {
				resizeMenu()
				resizeSetHeight()
				maskScrollbars()
			});

		}, 100);

	})
	
	// $(window).scroll(function () {

	// 	clearTimeout(resizeTimer);

	// 	resizeTimer = setTimeout(function () {

	// 		if ($("body").hasClass("hasHover")) {
	// 			resizeSetHeight()
	// 		}

	// 		$(window).on("orientationchange", function (event) {
	// 			resizeSetHeight()
	// 		});

	// 	}, 100);

	// })


});